import { GroupLinks } from '@/components/Footer';
import { TLink } from '@/libs/utils';
import { Endpoints, fetchData, strapiOptions } from './data-fetcher';
import { TSeoType } from './seo';
import qs from 'qs';
import { fetchModularPageById } from '@/libs/api';
import { fetchModularPagesByDomain, IModularPage } from '@/libs/api/pages';
import { fetchShortGameFeed } from '@/libs/api/game';
import { WEB_COMPONENTS_NAMES } from '@/libs/utils';
import { RawCarousel } from '@/features/carousel/model';
import { ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { themeVars } from '@/root/app/theme.css';

export type ThemeLayout = {
	id: number;
	theme: string;
	layout: 'wide' | 'centered';
	arenaMaxWidth: number;
	fontFamily: string;

	mode: 'minimalistic' | 'modern';
	roundCorners: boolean;
	carouselArrowsVisible: boolean;
};

export type ButtonOverride = {
	state: 'normal' | 'hover' | 'active';
	border?: string | null;
	background?: string | null;
	background_end?: string | null;
	should_button_use_border: boolean | null;
	color?: string | null;
};

export type StyleOverride = {
	bigHeroCard: {
		description: string | null;
		overlay: string | null;
		title: string | null;
		category: string | null;
		button: ButtonOverride[] | null;
	} | null;
	marketingCard: {
		description: string | null;
		overlay: string | null;
		button: ButtonOverride[] | null;
	} | null;
	seoCard: {
		title: string | null;
		description: string | null;
		button: ButtonOverride[] | null;
	} | null;
	standardCard: {
		title: string | null;
		hoverBlock: {
			type: 'partial' | 'full' | 'none' | null;
			color: string | null;
			background: string | null;
			buttonText: string | null;
			buttonBackground: string | null;
			buttonBackground_end: string | null;
			buttonTextColor: string | null;
			buttonBorderColor: string | null;
			should_button_use_border: boolean | null;
		};
	} | null;
	promoCard: {
		description: string | null;
		overlay: string | null;
		title: string | null;
		category: string | null;
		button: ButtonOverride[] | null;
	} | null;
	portraitCard: {
		hoverBlock: {
			type: 'partial' | 'full' | 'false' | null;
			color: string | null;
			background: string | null;
			buttonText: string | null;
			buttonBackground: string | null;
			buttonBackground_end: string | null;
			buttonTextColor: string | null;
			buttonBorderColor: string | null;
			should_button_use_border: boolean | null;
		};
	} | null;
	horizontalGenericLinks: {
		border_color: string | null;
		border_color_hover: string | null;
		border_color_active: string | null;
		label_color: string | null;
		label_color_hover: string | null;
		label_color_active: string | null;
		background_color: string | null;
		background_color_end: string | null;
		should_button_use_border: boolean | null;
		background_color_hover: string | null;
		background_color_hover_end: string | null;
		should_button_use_border_hover: boolean | null;
		background_color_active: string | null;
		background_color_active_end: string | null;
		should_button_use_border_active: boolean | null;
	};
	horizontalGenericLinksSearch: {
		border_color: string | null;
		border_color_hover: string | null;
		border_color_active: string | null;
		label_color: string | null;
		label_color_hover: string | null;
		label_color_active: string | null;
		background_color: string | null;
		background_color_end: string | null;
		should_button_use_border: boolean | null;
		background_color_hover: string | null;
		background_color_hover_end: string | null;
		should_button_use_border_hover: boolean | null;
		background_color_active: string | null;
		background_color_active_end: string | null;
		should_button_use_border_active: boolean | null;
	};
	topbar: { background: string | null } | null;
	topbarLink: ButtonOverride[] | null;
	sidebar: {
		divider: string | null;
		background: string | null;
		toggle: string | null;
		toggleBackground: string | null;
	} | null;
	sidebarItem: ButtonOverride[] | null;
	searchGoToHomepage: ButtonOverride[] | null;
	viewMore: ButtonOverride[] | null;
	tags: ButtonOverride[] | null;
} | null;

type ArenaTheme = StyleOverride & {
	button: ButtonOverride[] | null;
	borders: {
		'b-0': string;
		'b-2': string;
		'b-4': string;
		'b-8': string;
		'b-16': string;
		'b-24': string;
		'b-32': string;
		'b-40': string;
		'b-48': string;
		'b-56': string;
	};
	fontFamilies: {
		standard: string;
	};
	fontSizes: {
		'fs-12': string;
		'fs-14': string;
		'fs-16': string;
		'fs-18': string;
		'fs-20': string;
		'fs-22': string;
		'fs-24': string;
		'fs-28': string;
		'fs-32': string;
		'fs-36': string;
		'fs-40': string;
		'fs-44': string;
		'fs-48': string;
		'fs-52': string;
	};
	fontWeights: {
		light: string;
		regular: string;
		medium: string;
		semibold: string;
	};
	lineHeights: {
		'lh-14': string;
		'lh-16': string;
		'lh-18': string;
		'lh-20': string;
		'lh-22': string;
		'lh-24': string;
		'lh-28': string;
		'lh-32': string;
		'lh-36': string;
		'lh-40': string;
		'lh-44': string;
		'lh-48': string;
		'lh-52': string;
	};
	padding: {
		'p-0': string;
		'p-2': string;
		'p-4': string;
		'p-8': string;
		'p-16': string;
		'p-24': string;
		'p-32': string;
		'p-40': string;
		'p-48': string;
		'p-56': string;
	};
	radius: {
		'r-0': string;
		'r-2': string;
		'r-4': string;
		'r-8': string;
		'r-16': string;
		'r-24': string;
		'r-32': string;
		'r-40': string;
		'r-48': string;
		'r-56': string;
	};
};

type SideBarItem = {
	icon: { url: string };
	link: TLink;
};

type ArenaGameFeedId = {
	id: number;
	feedId: number;
};

export type Tag = {
	slug: string;
	name: string | null;
	image: { url: string } | null;
	carousels: any[] | null;
	seo: TSeoType | null;
	title: string | null;
	description: string | null;
};
export type Category = {
	id: number;
	slug: string;
	name: string | null;
	image: { url: string } | null;
	arena_tags: Tag[] | null;
	carousels: any[] | null;
	seo: TSeoType | null;
	title: string | null;
	description: string | null;
};

export type Badge = {
	id: number;
	label: string;
	backgroundColor: string;
	labelColor: string;
	arena: any;
};

type TLocaleList = {
	name: string;
	list: TLocale[] | null;
};
export type TLocale = {
	id: number;
	code: string;
};
type TheySellAd = {
	displayAdUrl: string;
	videoAdUrl: string;
};
export type TExternalScriptLoadingType = 'cdnURL' | 'rawScript' | 'internal';
export type TExternalScriptRaw = {
	id: number;
	name: string;
	script: {
		mainScript: string;
		scriptLoadingType: TExternalScriptLoadingType;
		codeBeforeMainScript?: string;
		codeAfterMainScript?: string;
		isLoaded?: boolean;
	};
};
export type TExternalScript = Omit<TExternalScriptRaw, 'script'> & TExternalScriptRaw['script'];
export type TNotificationBar = {
	id: number;
	title?: string | null;
	text?: string | null;
	bgColor?: string | null;
	textColor?: string | null;
	closeColor?: string | null;
	closeIcon?: { url: string } | null;
	dismissButton?: boolean | null;
	autoDismissTime?: 's-15' | 's-20' | 's-30' | null;
	autoDismiss?: boolean | null;
	link?: TLink;
	cta?: TLink;
	ctaStyling?: ButtonOverride[] | null;
	icon?: { url: string } | null;
	analyticId?: string | null;
};
export type ArenaDataState = {
	layout: {
		themeLayout: ThemeLayout | null;
		styleOverride: StyleOverride | null;
		analyticId: string | null;
		topBar: boolean | null;
		topBarLinks: TLink[];
		sideBar: boolean | null;
		sidebar_groups:
			| {
					sidebar_items: SideBarItem[];
					icon: { url: string };
					link: TLink;
			  }[]
			| null;
		footer: {
			logo: { url: string } | null;
			groups: GroupLinks[] | null;
			socialLinks: TLink[] | null;
			background: string | null;
			logoUrl: string | null;
			fontColor: string | null;
			text: string | null;
			manageCookiesLabel: string | null;
		} | null;
		categoryTagAdsDivider: boolean | null;
		categoryTagAdsDividerInterval: number | null;
	} | null;
	customScripts: string | null;
	analyticId: string | null;
	game_page: { id: number } | null;
	logo: { url: string } | null;
	logo_label: string | null;
	favIcon: { url: string } | null;
	game_feed: ArenaGameFeedId | null;
	arena_categories: Category[] | null;
	arena_tags: Tag[] | null;
	arena_badges: any | null;
	arena_theme: ArenaTheme | null;
	categoriesSlug: string | null;
	localeList: TLocaleList | null;
	theySellAd: TheySellAd | null;
	external_scripts: TExternalScriptRaw[] | null;
	notification_bars: TNotificationBar[] | null;
};

function modifyArenaThemePropToInlineVar<T>(prop: any) {
	return Object.fromEntries(Object.entries(prop ?? {}).map(([key, value]) => [key.split('-')[1], value])) as T;
}

function initializeInlineVars(arena_theme: ArenaTheme | null) {
	const fontSize = modifyArenaThemePropToInlineVar<(typeof themeVars)['fontSize']>(arena_theme?.fontSizes),
		lineHeight = modifyArenaThemePropToInlineVar<(typeof themeVars)['lineHeight']>(arena_theme?.lineHeights),
		padding = modifyArenaThemePropToInlineVar<(typeof themeVars)['padding']>(arena_theme?.padding),
		radius = modifyArenaThemePropToInlineVar<(typeof themeVars)['radius']>(arena_theme?.radius),
		borderWidth = modifyArenaThemePropToInlineVar<(typeof themeVars)['borderWidth']>(arena_theme?.borders);
	return assignInlineVars(themeVars, {
		font: { standard: arena_theme?.fontFamilies.standard ?? themeVars.font.standard },
		fontWeight: arena_theme?.fontWeights ?? themeVars.fontWeight,
		fontSize,
		lineHeight,
		padding,
		radius,
		borderWidth,
	});
}

export const fetchArenaData = async (domain: string): Promise<ArenaDataState> => {
	const url = Endpoints.getArenaData(domain),
		arenaData = (await fetchData(url, strapiOptions)) as ArenaDataState,
		inlineVars = initializeInlineVars(arenaData.arena_theme);
	return arenaData;
};

export const fetchArenaLocales = async (domain: string): Promise<ArenaDataState> => {
	const url = Endpoints.getArenaLocales(domain);
	return await fetchData(url, strapiOptions);
};

export const fetchArenaDataByQuery = async (query: string): Promise<any> => {
	const url = Endpoints.getArenaDataByQuery(query);
	const res = await fetchData(url, strapiOptions);
	return res?.data?.[0];
};

export const filterCarouselBasedOnFeed = (carousel: RawCarousel, gameFeedSlugs: string[]): RawCarousel => {
	const filteredCards = carousel.carousels?.[0].cards.filter((card) => {
		if (card.type === 'SEO' || card.type === 'Marketing') {
			return true;
		}
		return gameFeedSlugs.includes(card.game?.slug);
	});
	return {
		...carousel,
		carousels: [{ ...carousel.carousels[0], cards: filteredCards }],
	};
};

export const genericPageDataSampleFn = (pageData: IModularPage, gameFeed: ShortGameModelFromFeed[]) => {
	//filter out page data based on game feed games
	const gameFeedSlugs = gameFeed.map((game) => game.slug);
	const filteredContent = pageData.content.map((component) =>
		component.__component === WEB_COMPONENTS_NAMES.Carousel
			? filterCarouselBasedOnFeed(component as RawCarousel, gameFeedSlugs)
			: component,
	);
	return { ...pageData, content: filteredContent } as IModularPage;
};

export const getPageByID = async (foundPageId: number, arenaData: ArenaDataState): Promise<IModularPage> => {
	const lang = arenaData.localeList?.list?.[0]?.code ?? 'en';
	const feedId = arenaData.game_feed?.feedId ?? null;
	const modularPage = await fetchModularPageById(foundPageId);
	const gameFeed = await fetchShortGameFeed(feedId ?? 0, lang);
	//check if data can be null
	const genericPageData = genericPageDataSampleFn(modularPage, gameFeed);
	return genericPageData;
};

export const getPages = async (slug: string | undefined, domain: string) => {
	const pages = await fetchModularPagesByDomain(domain);
	//Homepage is by convention the first modular page
	const homePage = pages?.[0];
	const stringSlug: string = slug ?? '';
	const isHomePageSlug = stringSlug === homePage?.slug || stringSlug === '';
	const foundPage = isHomePageSlug ? homePage : pages.find((p) => p.slug === stringSlug);
	return { foundPageId: foundPage?.id, isHomePageSlug };
};

export const fetchArenaConfig = async (domain: string): Promise<ArenaDataState> => {
	const arenaData = await fetchArenaData(domain);
	return arenaData;
};
export const fetchStyleOverride = async (domain: string) => {
	const data = await fetchArenaDataByQuery(
		qs.stringify({
			filters: { domain: { $eq: domain } },
			fields: ['id'],
			populate: {
				layout: {
					populate: {
						styleOverride: {
							populate: {
								standardCard: {
									populate: {
										hoverBlock: true,
									},
								},
								portraitCard: {
									populate: {
										hoverBlock: true,
									},
								},
								bigHeroCard: {
									populate: {
										button: true,
									},
								},
								promoCard: {
									populate: {
										button: true,
									},
								},
								marketingCard: {
									populate: {
										button: true,
									},
								},
								seoCard: {
									populate: {
										button: true,
									},
								},
								topbar: true,
								topbarLink: true,
								sidebar: true,
								sidebarItem: true,
								tags: true,
								searchGoToHomepage: true,
								viewMore: true,
								horizontalGenericLinks: true,
								horizontalGenericLinksSearch: true,
							},
						},
					},
				},
			},
		}),
	);
	return data;
};
export const fetchNotificationBars = async (domain: string) => {
	const data = await fetchArenaDataByQuery(
		qs.stringify({
			filters: { domain: { $eq: domain } },
			fields: ['id'],
			populate: {
				notification_bars: {
					populate: {
						closeIcon: { fields: ['url'] },
						icon: { fields: ['url'] },
						link: true,
						cta: true,
						ctaStyling: true,
					},
					fields: [
						'id',
						'title',
						'text',
						'bgColor',
						'textColor',
						'closeColor',
						'dismissButton',
						'autoDismissTime',
						'autoDismiss',
						'analyticId',
					],
				},
			},
		}),
	);
	return data;
};
